define("discourse/plugins/retort/discourse/services/retort", ["exports", "@glimmer/tracking", "@ember/application", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _application, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RetortService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "messageBus", [_service.inject]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "picker", [_tracking.tracked]))();
    #picker = (() => (dt7948.i(this, "picker"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "model", [_tracking.tracked]))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "widgets", [_tracking.tracked]))();
    #widgets = (() => (dt7948.i(this, "widgets"), void 0))();
    init() {
      super.init(...arguments);
    }
    initBus() {
      if (this.model.id) {
        this.messageBus.unsubscribe(`/retort/topics/${this.model.id}`);
      }
      if (this.currentUser) {
        this.messageBus.subscribe(`/retort/topics/${this.model.id}`, _ref => {
          let {
            id,
            retorts
          } = _ref;
          const post = this.postFor(id);
          if (!post) {
            return;
          }
          post.setProperties({
            retorts
          });
          this.get(`widgets.${id}`).scheduleRerender();
        });
      }
    }
    postFor(id) {
      return (this.model.postStream.posts || []).find(p => p.id === id);
    }
    storeWidget(helper) {
      if (!this.widgets) {
        this.widgets = {};
      }
      this.set(`widgets.${helper.getModel().id}`, helper.widget);
    }
    updateRetort(_ref2, retort) {
      let {
        id
      } = _ref2;
      return (0, _ajax.ajax)(`/retorts/${id}.json`, {
        type: "POST",
        data: {
          retort
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    disabledCategories() {
      const categories = this.siteSettings.retort_disabled_categories.split("|");
      return categories.map(cat => cat.toLowerCase()).filter(Boolean);
    }
    disabledFor(postId) {
      if (!this.currentUser) {
        return true;
      }
      const post = this.postFor(postId);
      if (!post) {
        return true;
      }
      if (!post.topic.details.can_create_post) {
        return true;
      }
      if (post.topic.archived) {
        return true;
      }
      if (!post.topic.category) {
        return false;
      } else {
        const categoryName = post.topic.category.name;
        const disabledCategories = this.disabledCategories();
        return categoryName && disabledCategories.includes(categoryName.toString().toLowerCase());
      }
    }
    openPicker(post) {
      this.controller = (0, _application.getOwner)(this).lookup("controller:topic");
      this.set("picker.isActive", true);
      this.set("picker.post", post);
      this.set("controller.renderRetortPicker", true);
    }
    setPicker(picker) {
      this.set("picker", picker);
      this.picker.emojiSelected = retort => {
        this.updateRetort(this.picker.post, retort).then(() => {
          this.set("picker.isActive", false);
        });
      };
    }
  }
  _exports.default = RetortService;
});